import React, { useContext, useMemo, useState, useEffect, useRef } from 'react';
import moment from 'moment-timezone';
import _ from 'lodash';
import { formatAsCurrency, getCustomerFirstNameAndLastInitial } from 'utils/misc';

import { _bulk, _rates, _user } from 'std';

import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';

import {
    Typography,
    Divider,
    Button,
    IconButton,
    colors,
    withTheme,
    Card,
    CardActionArea,
    Icon,
    Tooltip
} from '@material-ui/core';

import { Icon as MDIcon } from '@mdi/react';
import { mdiClose, mdiSack, mdiPrinter, mdiWrench, mdiQrcodeScan } from '@mdi/js';
import { getComEquivalent, shortenSubComName } from 'containers/Operators/PORScreen/NumberOfBagsDialog';
import * as allIcons from '@mdi/js';

const CountsScreenV2 = React.forwardRef((props, ref) => {
    const scrollPanelRef = useRef();
    const {
        theme,
        bulk = {},
        disableEditing,
        inProgress,
        customFeesForm,
        skus,
        hideCounter = false,
        countFontSize = null,
        // totalsFontSize = null,
        headerFooterColor = null,
        // headerFooterTextColor = props.theme.palette.text.primary,
        padding = 2,
        onRemoveCounts,
        onRemoveCustomFee,
        onClearCountsAndFees,
        setIdleCountdown,
        resetTimeoutCount,
        printingReceipt,
        commodityColors,
        setCurrentCountingSessionId,
        commodities,
        lang,
        currentCountingSessionId,
        operator,
        collector,
        promoCodes,
        setCreateEditNumberOfCommoditiesDialogOpen,
        handleRemoveCountingSession,
        handleRemoveSessionlessCounts,
        handleRemoveCustomFeeFromSessionAndBulk,
        onImageDialog,
        setImages,
        handleScrollBarWidth,
        setCreatEditNumberOfCommoditiesMode
    } = props;
    const warnAction = useContext(ConfirmDialogContext);

    // const aggregatedCounts = useMemo(() => _bulk.getCountsItemsWithAmount(bulk, skus[bulk.skuType] || []), [
    //     bulk,
    //     skus
    // ]);

    const [sessionlessCounts, setSessionlessCounts] = useState([]);
    const [sessionlessFees, setSessionlessFees] = useState([]);
    const [sessionlessCountsItemsWithAmount, setSessionlessCountsItemsWithAmount] = useState([]);
    const [countImageUrlMap, setCountImageUrlMap] = useState({});
    useEffect(() => {
        setSessionlessCounts(getSessionlessCounts());
        setSessionlessFees(getSessionlessFees());
        setCountImageUrlMap(getCountImageUrlMap());
    }, [bulk]);
    function getSessionlessCounts() {
        let sessionItemIds = {};
        let newSessionCounts = [];
        _.get(bulk, 'countingSessions', []).forEach(session => {
            _.get(session, 'items', []).forEach(item => {
                let itemId = _.get(item, '_id', null);
                if (!_.isNil(itemId)) {
                    sessionItemIds[itemId] = itemId;
                }
            });
        });
        let sessionItemIdsArray = Object.values(sessionItemIds);
        _.get(bulk, 'counts', []).forEach(count => {
            let itemId = _.get(_.first(_.get(count, 'items', [])), '_id', null);
            if (!_.isNil(itemId) && _.isArray(sessionItemIdsArray) && !sessionItemIdsArray.includes(itemId)) {
                newSessionCounts.push(count);
            }
        });
        setSessionlessCountsItemsWithAmount(
            _bulk.getCountsItemsWithAmount({ counts: newSessionCounts }, skus[bulk.skuType] || [])
        );
        return newSessionCounts;
    }
    function getSessionlessFees() {
        let sessionFeeIds = {};
        let newSessionlessFees = [];
        _.get(bulk, 'countingSessions', []).forEach(session => {
            _.get(session, 'customFees', []).forEach(fee => {
                let feeId = _.get(fee, '_id', null);
                if (!_.isNil(feeId)) {
                    sessionFeeIds[feeId] = feeId;
                }
            });
        });
        _.get(bulk, 'customFees', []).forEach((fee, index) => {
            let feeId = _.get(fee, '_id', null);

            //Hide generated fees(e.g. min pickup fee) to avoid confusing collectors/counters
            const hideFee = _.get(fee, 'generatedOnBulkCompletion', false) && !isAdminOrCSR;

            if (!_.isNil(feeId) && sessionFeeIds[feeId] === undefined && !hideFee) {
                newSessionlessFees.push({ ...fee, index: index });
            }
        });
        return newSessionlessFees;
    }
    function getCountImageUrlMap() {
        if (_.isNil(bulk)) return {};
        let newCountImageMap = {};
        _.get(bulk, 'counts', []).forEach(count => {
            _.get(count, 'items', []).forEach(item => {
                if (!_.isNil(_.get(item, 'countImageUrl', null))) {
                    newCountImageMap[item._id] = item.countImageUrl;
                }
            });
        });
        return newCountImageMap;
    }

    const generateCommodityList = (bulk, session, commodities) => {
        const commodityCounted = _.get(session, 'commodityCounted', []);
        const commodityList = [];

        const commodity = _.find(commodities, {
            skuType: _.get(bulk, 'skuType', '')
        });
        let subCommodityLookup = _.get(commodity, 'subCommodities', []);

        const bagtag = session.bagtag;
        const dateBagtagPrinted = session.dateBagtagPrinted;

        for (let commodityCount of commodityCounted) {
            let amount = _.get(commodityCount, 'amount', 0);
            if (amount === 0) continue;

            let isSubCommodity = _.get(commodityCount, 'isSubCommodity', true);
            if (!isSubCommodity) {
                commodityList.push({
                    color: _.get(commodity, 'color', colors.blue[300]),
                    icon: allIcons[_.get(commodity, 'iconName', '')],
                    amount: amount,
                    name: shortenSubComName(_.get(bulk, 'commodityUOM', 'bag'), amount),
                    bagtag,
                    dateBagtagPrinted
                });

                continue;
            }

            let subCommodityId = _.get(commodityCount, 'subCommodity', 0);
            let subCommodity = _.find(subCommodityLookup, subComLookupItem => {
                return subComLookupItem._id === subCommodityId;
            });
            let name = _.get(subCommodity, `name.${lang}`, '');
            commodityList.push({
                color: _.get(subCommodity, 'color', colors.blue[300]),
                icon: allIcons[convertToImportName(_.get(subCommodity, 'iconName', ''))],
                amount: amount,
                name: shortenSubComName(name, amount)
            });
        }

        return commodityList;
    };

    const countsStyle = { fontSize: countFontSize, paddingLeft: padding };
    const isAdminOrCSR = _user.isSystemAdmin(operator) || _user.isInternalRole(operator);

    useEffect(() => {
        const checkForScrollbar = () => {
            if (scrollPanelRef.current) {
                const scrollBarWidth = scrollPanelRef.current.offsetWidth - scrollPanelRef.current.clientWidth;
                handleScrollBarWidth(scrollBarWidth);
            }
        };
        checkForScrollbar();

        window.addEventListener('resize', checkForScrollbar);
        return () => {
            window.removeEventListener('resize', checkForScrollbar);
        };
    }, []);
    return (
        <div
            // ref={ref}
            style={{
                // overflowY: 'auto',
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                margin: !printingReceipt ? 0 : 20
            }}
        >
            <div
                ref={scrollPanelRef}
                style={{ overflowY: 'auto', flex: `1 1 auto`, minHeight: !printingReceipt ? '150px' : '0px' }}
                id="custom-scroller"
            >
                {_.get(bulk, 'countingSessions', []).map((session, idx) => {
                    let sessionCounts = _bulk.getCountsItemsWithAmount({ counts: [session] }, skus[bulk.skuType] || []);

                    let bagtag = session.bagtag;
                    let dateBagtagPrinted = session.dateBagtagPrinted;

                    let tomraVariables = session.tomraVariables;
                    let machineCounter = '';
                    let collectorId = '';
                    if (!_.isEmpty(tomraVariables)) {
                        const machine = tomraVariables.rvmName;
                        const serialNumber = tomraVariables.rvmSerialNo;
                        machineCounter = `${machine.replace(' ', '')}-${serialNumber}`;

                        collectorId = tomraVariables.locationName;
                    }

                    const isAPISubmission = !_.isEmpty(bagtag); // May not always apply. Review in future if necessary

                    const renderList = generateCommodityList(bulk, session, commodities);

                    let hasEditPerm =
                        operator._id === _.get(session, 'operator._id', '') || _user.isCollectorAdmin(operator);

                    return (
                        <Card
                            data-cy={`bulkcard-${bulk.label}-${bulk.skuType}`}
                            elevation={0}
                            style={{
                                marginTop: theme.spacing.unit,
                                border: '1px solid',
                                borderColor:
                                    currentCountingSessionId === _.get(session, '_id', '')
                                        ? theme.palette.primary.main
                                        : theme.palette.grey[300],
                                maxHeight: '100%',
                                position: 'relative',
                                padding: '4px 0'
                            }}
                        >
                            {!isAPISubmission && (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        paddingTop: theme.spacing.unit / 4,
                                        paddingLeft: theme.spacing.unit / 2,
                                        paddingRight: theme.spacing.unit * 2,
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row'
                                        }}
                                    >
                                        {currentCountingSessionId === _.get(session, '_id', '') &&
                                            (!_bulk.isCompleted(bulk) && !_bulk.isInvoiced(bulk)) && (
                                                <IconButton
                                                    style={{ padding: 0 }}
                                                    onClick={() => {
                                                        setCreatEditNumberOfCommoditiesMode('edit');
                                                        setCreateEditNumberOfCommoditiesDialogOpen(true);
                                                    }}
                                                    data-cy={`count-session-edit-${idx}`}
                                                >
                                                    <Icon>edit</Icon>
                                                </IconButton>
                                            )}
                                        {currentCountingSessionId === _.get(session, '_id', '') &&
                                            _.get(bulk, 'countingSessions', []).length > 1 &&
                                            (!_bulk.isCompleted(bulk) && !_bulk.isInvoiced(bulk)) && (
                                                <IconButton
                                                    data-cy={`count-session-delete-${idx}`}
                                                    style={{ padding: 0 }}
                                                    onClick={() => {
                                                        if (_.get(bulk, 'countingSessions', []).length < 2) return;
                                                        warnAction(() => {
                                                            handleRemoveCountingSession(currentCountingSessionId);
                                                        }, 'You are about to delete this session. Do you wish to continue?');
                                                    }}
                                                >
                                                    <Icon>delete</Icon>
                                                </IconButton>
                                            )}
                                        {renderList.length === 0 && (
                                            <Typography
                                                style={{
                                                    whiteSpace: 'nowrap',
                                                    alignSelf: 'center',
                                                    justifyContent: 'center'
                                                }}
                                            >
                                                No items
                                            </Typography>
                                        )}
                                        <div
                                            style={{
                                                flexWrap: 'wrap',
                                                display: 'flex',
                                                flexDirection: 'row'
                                            }}
                                        >
                                            {(renderList || []).map(renderItem => {
                                                return (
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            width: 'max-content',
                                                            flexDirection: 'row',
                                                            marginRight: 5
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                alignSelf: 'center',
                                                                justifyContent: 'center',
                                                                height: 16
                                                            }}
                                                        >
                                                            <MDIcon
                                                                path={_.get(renderItem, 'icon', '')}
                                                                size={0.7}
                                                                color={_.get(renderItem, 'color', '')}
                                                            />
                                                        </div>
                                                        <Typography
                                                            style={{
                                                                whiteSpace: 'nowrap',
                                                                alignSelf: 'center',
                                                                justifyContent: 'center'
                                                            }}
                                                        >
                                                            {_.get(renderItem, 'amount', '')}{' '}
                                                            {_.get(renderItem, 'name', '')}
                                                        </Typography>

                                                        {/* {renderItem.bagtag && (
                                                        <Tooltip title={renderItem.bagtag}>
                                                            <span style={{ alignSelf: 'center' }}>
                                                                <MDIcon
                                                                    path={mdiTagArrowDown}
                                                                    size={0.7}
                                                                    style={{
                                                                        marginLeft: theme.spacing.unit / 2
                                                                    }}
                                                                    color={colors.grey[500]}
                                                                />
                                                            </span>
                                                        </Tooltip>
                                                    )} */}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    {currentCountingSessionId === _.get(session, '_id', '') && (
                                        <Typography
                                            style={{
                                                whiteSpace: 'nowrap',
                                                alignSelf: 'center',
                                                justifyContent: 'center',
                                                color: theme.palette.grey[500],
                                                fontSize: 13
                                            }}
                                        >
                                            sorting
                                        </Typography>
                                    )}
                                    {currentCountingSessionId !== _.get(session, '_id', '') &&
                                        (isAdminOrCSR || hasEditPerm) &&
                                        (!_bulk.isCompleted(bulk) && !_bulk.isInvoiced(bulk)) && (
                                            <div
                                                style={{
                                                    alignSelf: 'center',
                                                    justifyContent: 'center'
                                                }}
                                            >
                                                <Button
                                                    data-cy={`count-session-select-${idx}`}
                                                    color="primary"
                                                    style={{
                                                        fontSize: 12,
                                                        minWidth: '36px',
                                                        maxWidth: '96px',
                                                        minHeight: '26px',
                                                        maxHeight: '26px',
                                                        padding: theme.spacing.unit
                                                    }}
                                                    variant="contained"
                                                    size="small"
                                                    onClick={e => {
                                                        setCurrentCountingSessionId(session._id);
                                                    }}
                                                >
                                                    edit
                                                </Button>
                                            </div>
                                        )}
                                </div>
                            )}
                            {isAPISubmission && (
                                <div
                                    style={{
                                        marginTop: theme.spacing.unit * 0.75,
                                        marginLeft: theme.spacing.unit * 0.75,
                                        marginRight: theme.spacing.unit * 2,
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        alignItems: 'center',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <Tooltip title={'Bagtag'}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <MDIcon
                                                path={bulk.bulkType == 'walk-in' ? mdiQrcodeScan : mdiSack}
                                                size={0.8}
                                                style={{
                                                    marginTop: theme.spacing.unit / 4,
                                                    marginLeft: theme.spacing.unit / 2,
                                                    marginRight: theme.spacing.unit / 2
                                                }}
                                                color={'000000'}
                                            />

                                            <Typography style={{ fontSize: '11px', fontWeight: 'bold' }}>
                                                {bagtag}
                                            </Typography>
                                        </div>
                                    </Tooltip>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        {dateBagtagPrinted && (
                                            <Tooltip title={'Date Printed'}>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        marginRight: theme.spacing.unit
                                                    }}
                                                >
                                                    <MDIcon
                                                        path={mdiPrinter}
                                                        size={0.8}
                                                        style={{
                                                            marginTop: theme.spacing.unit / 4,
                                                            marginLeft: theme.spacing.unit / 2,
                                                            marginRight: theme.spacing.unit / 2
                                                        }}
                                                        color="000000"
                                                    />
                                                    {/* <Typography style={{ fontSize: '10px' }}>
                                                        {moment(dateBagtagPrinted).format('MMM D, YYYY')}
                                                        <br />
                                                        {moment(dateBagtagPrinted).format('h:mm A')}
                                                    </Typography> */}
                                                </div>
                                            </Tooltip>
                                        )}
                                        {/* {machineCounter && (
                                        <Tooltip title={'Processed by'}>
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <MDIcon
                                                    path={mdiAccountHardHat}
                                                    size={0.6}
                                                    style={{
                                                        marginTop: theme.spacing.unit / 4,
                                                        marginLeft: theme.spacing.unit / 2,
                                                        marginRight: theme.spacing.unit / 2
                                                    }}
                                                    color={colors.grey[500]}
                                                />

                                                <Typography style={{ fontSize: '12px' }}>{machineCounter}</Typography>
                                            </div>
                                        </Tooltip>
                                    )} */}
                                        <Tooltip title={`Date Processed (${machineCounter} - ${collectorId})`}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <MDIcon
                                                    path={mdiWrench}
                                                    size={0.8}
                                                    style={{
                                                        marginTop: theme.spacing.unit / 4,
                                                        marginLeft: theme.spacing.unit / 2,
                                                        marginRight: theme.spacing.unit / 2
                                                    }}
                                                    color="000000"
                                                />

                                                {/* <Typography style={{ fontSize: '10px' }}>
                                                    {moment(session.dateCounted).format('MMM D, YYYY')}
                                                    <br />
                                                    {moment(session.dateCounted).format('h:mm A')}
                                                </Typography> */}
                                            </div>
                                        </Tooltip>
                                    </div>
                                </div>
                            )}
                            <Divider style={{ marginTop: theme.spacing.unit }} />

                            <table
                                style={{
                                    width: '100%',
                                    // padding: theme.spacing.unit / 2,
                                    paddingTop: '8px',
                                    paddingRight: '8px'
                                }}
                            >
                                <tbody style={{ backgroundColor: headerFooterColor }}>
                                    {sessionCounts.map((sessionCount, i) => {
                                        let items = sessionCount.items || [];

                                        // TODO: find was in UI to show bulk counts versus singular counts
                                        if (items.length === 0) {
                                            return (
                                                <Typography
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        alignSelf: 'center',
                                                        justifyContent: 'center'
                                                    }}
                                                >
                                                    No counts
                                                </Typography>
                                            );
                                        }

                                        if (isAPISubmission) {
                                            const groupedItems = _.groupBy(items, 'sku');
                                            const aggregatedItems = [];

                                            Object.keys(groupedItems).forEach(key => {
                                                const sku = key;
                                                const groupedItem = groupedItems[sku];

                                                if (_.isEmpty(groupedItem)) return;

                                                const totalQuantity = _.sumBy(groupedItem, 'quantity');
                                                const totalValue = _.sumBy(groupedItem, 'value');

                                                const aggregate = _.cloneDeep(groupedItem[0]);
                                                aggregate.quantity = totalQuantity;
                                                aggregate.value = totalValue;

                                                aggregatedItems.push(aggregate);
                                            });

                                            items = aggregatedItems;
                                        }

                                        return items.map((item, j) => {
                                            const epicStyle = {};
                                            let countImageUrl = countImageUrlMap[item._id];
                                            return (
                                                <tr
                                                    key={`${item.description}-${j}`}
                                                    style={{
                                                        marginBottom: 5,
                                                        paddingBottom: 5
                                                    }}
                                                >
                                                    <td
                                                        style={{
                                                            width: '38%'
                                                        }}
                                                    >
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            {!isAPISubmission &&
                                                                !disableEditing &&
                                                                !printingReceipt &&
                                                                currentCountingSessionId ===
                                                                    _.get(session, '_id', '') && (
                                                                    <>
                                                                        {!disableEditing && (
                                                                            <IconButton
                                                                                data-cy="delete-session-count"
                                                                                disabled={disableEditing || inProgress}
                                                                                style={{ padding: 0 }}
                                                                                onClick={() => {
                                                                                    warnAction(() => {
                                                                                        onRemoveCounts(
                                                                                            sessionCount._id,
                                                                                            item._id
                                                                                        );
                                                                                        resetTimeoutCount();
                                                                                    }, `Are you sure you want to delete [${item.description} ${item.size} x ${item.quantity}] from the counts?`);
                                                                                    setIdleCountdown(0);
                                                                                    resetTimeoutCount();
                                                                                }}
                                                                            >
                                                                                <MDIcon
                                                                                    path={mdiClose}
                                                                                    size={0.8}
                                                                                    color={colors.red[500]}
                                                                                />
                                                                            </IconButton>
                                                                        )}
                                                                        {!_.isNil(countImageUrl) && (
                                                                            <Tooltip
                                                                                title={'View AI count image'}
                                                                                disableFocusListener
                                                                                disableTouchListener
                                                                            >
                                                                                <IconButton
                                                                                    style={{
                                                                                        marginLeft: 4,
                                                                                        padding: 0
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        if (!_.isEmpty(countImageUrl)) {
                                                                                            setImages([countImageUrl]);
                                                                                            onImageDialog(true);
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    <MDIcon
                                                                                        path={allIcons.mdiEye}
                                                                                        size={0.8}
                                                                                    />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        )}
                                                                    </>
                                                                )}
                                                            <Typography
                                                                noWrap
                                                                style={countsStyle}
                                                                data-cy={`counts-panel-item-${j}-description`}
                                                            >
                                                                {item.description} {item.size}
                                                            </Typography>
                                                        </div>
                                                    </td>
                                                    <td style={{ width: '32%' }}>
                                                        {!hideCounter && !isAPISubmission && (
                                                            <Typography
                                                                data-cy={`counts-panel-item-${j}-counter`}
                                                                style={{
                                                                    ...countsStyle,
                                                                    fontSize: '70%'
                                                                }}
                                                            >
                                                                <span style={{ marginRight: theme.spacing.unit }}>
                                                                    {getCustomerFirstNameAndLastInitial(
                                                                        sessionCount.operator
                                                                    )}
                                                                </span>
                                                                <span>
                                                                    {moment(item.dateCounted).format('DD/MM/YY h:mma')}
                                                                </span>
                                                            </Typography>
                                                        )}
                                                    </td>
                                                    <td
                                                        // style={{ width: 'auto' }}
                                                        style={{ width: '15%' }}
                                                        data-cy={`counts-panel-item-${j}-quantity`}
                                                    >
                                                        <Typography style={{ ...countsStyle, textAlign: 'right' }}>
                                                            {_.get(item, 'quantity', 0)}
                                                        </Typography>
                                                    </td>
                                                    <td
                                                        // style={{ width: 'auto' }}
                                                        style={{ width: '15%' }}
                                                        data-cy={`counts-panel-item-${j}-value`}
                                                    >
                                                        <Typography style={{ ...countsStyle, textAlign: 'right' }}>
                                                            {formatAsCurrency(item.value || 0)}
                                                        </Typography>
                                                    </td>
                                                </tr>
                                            );
                                        });
                                    })}
                                    {(_.get(session, 'customFees', []) || []).map((fee, index) => {
                                        return (
                                            <tr key={`${fee.amount}-${index}`}>
                                                <td
                                                    style={{
                                                        width: 'auto',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between'
                                                    }}
                                                >
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        {!printingReceipt &&
                                                            currentCountingSessionId === _.get(session, '_id', '') && (
                                                                <DeleteColumn
                                                                    style={countsStyle}
                                                                    show={!disableEditing}
                                                                    disabled={disableEditing || inProgress}
                                                                    onFunction={() => {
                                                                        handleRemoveCustomFeeFromSessionAndBulk(
                                                                            session._id,
                                                                            fee._id
                                                                        );
                                                                        setIdleCountdown(0);
                                                                        resetTimeoutCount();
                                                                    }}
                                                                />
                                                            )}
                                                        <Typography style={countsStyle}>{fee.description}</Typography>
                                                    </div>
                                                    {!hideCounter && (
                                                        <Typography
                                                            style={{
                                                                ...countsStyle,
                                                                fontSize: '70%'
                                                            }}
                                                        >
                                                            <span style={{ marginRight: theme.spacing.unit }}>
                                                                {getFeeName(fee)}
                                                            </span>
                                                            <span>
                                                                {!_.isNil(_.get(fee, 'dateApplied'))
                                                                    ? moment(fee.dateApplied).format('DD/MM/YY h:mma')
                                                                    : 'N/A'}
                                                            </span>
                                                        </Typography>
                                                    )}
                                                </td>
                                                <td style={{ width: '15%', textAlign: 'right' }}>
                                                    <Typography style={{ ...countsStyle, textAlign: 'right' }}>
                                                        {_.get(fee, 'quantity', 1)}
                                                    </Typography>
                                                </td>
                                                <td style={{ width: '15%', textAlign: 'right' }}>
                                                    <Typography
                                                        style={{
                                                            color:
                                                                fee.amount > 0
                                                                    ? theme.palette.error.main
                                                                    : colors.green[400],
                                                            ...countsStyle,
                                                            textAlign: 'right'
                                                        }}
                                                    >
                                                        {formatAsCurrency(-fee.amount)}
                                                    </Typography>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            <div
                                style={{
                                    backgroundColor: commodityColors[bulk.skuType] || colors.grey[500],
                                    position: 'absolute',
                                    height: '100%',
                                    width: 8,
                                    right: 0,
                                    top: 0
                                }}
                            />
                        </Card>
                    );
                })}
                {(sessionlessCounts.length > 0 || sessionlessFees.length > 0) && (
                    <Card
                        data-cy={`bulkcard-${bulk.label}-${bulk.skuType}`}
                        elevation={0}
                        style={{
                            marginTop: theme.spacing.unit,
                            border: '1px solid',
                            borderColor: theme.palette.grey[300],
                            maxHeight: '100%',
                            position: 'relative',
                            padding: '4px 0'
                        }}
                    >
                        {/* <div
                            style={{
                                // display: 'flex',
                                // flexDirection: 'row',
                                paddingLeft: theme.spacing.unit / 2,
                                paddingRight: theme.spacing.unit * 2
                                // justifyContent: 'space-between'
                            }}
                        >
                            <div
                                style={
                                    {
                                        // display: 'flex',
                                        // flexDirection: 'row'
                                    }
                                }
                            >
                                <Typography
                                    style={{
                                        whiteSpace: 'nowrap',
                                        alignSelf: 'center',
                                        justifyContent: 'center'
                                    }}
                                >
                                    Session-less items
                                </Typography>
                            </div>
                        </div>
                        <Divider style={{ marginTop: theme.spacing.unit }} /> */}
                        <table
                            style={{
                                width: '100%',
                                // padding: theme.spacing.unit / 2
                                paddingTop: '8px',
                                paddingRight: '8px'
                            }}
                        >
                            <tbody style={{ backgroundColor: headerFooterColor }}>
                                {sessionlessCountsItemsWithAmount.map((count, i) => {
                                    if (count.items.length === 0) {
                                        return (
                                            <Typography
                                                style={{
                                                    whiteSpace: 'nowrap',
                                                    alignSelf: 'center',
                                                    justifyContent: 'center'
                                                }}
                                            >
                                                No counts
                                            </Typography>
                                        );
                                    }
                                    return count.items.map((item, j) => {
                                        const epicStyle = {};

                                        return (
                                            <tr
                                                key={`${item.description}-${j}`}
                                                style={{
                                                    marginBottom: 5,
                                                    paddingBottom: 5
                                                }}
                                            >
                                                <td
                                                    style={{
                                                        width: 'auto',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between'
                                                    }}
                                                >
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        {!disableEditing && !printingReceipt && (
                                                            <DeleteColumn
                                                                style={countsStyle}
                                                                show={!disableEditing} // show del for all items in bulk count
                                                                disabled={disableEditing || inProgress}
                                                                epicStyle={epicStyle}
                                                                onFunction={() => {
                                                                    warnAction(() => {
                                                                        handleRemoveSessionlessCounts(
                                                                            count._id,
                                                                            item._id
                                                                        );
                                                                        resetTimeoutCount();
                                                                    }, `Are you sure you want to delete [${item.description} ${item.size} x ${item.quantity}] from the counts?`);
                                                                    setIdleCountdown(0);
                                                                    resetTimeoutCount();
                                                                }}
                                                            />
                                                        )}
                                                        <Typography
                                                            noWrap
                                                            style={{ ...countsStyle, width: 'auto' }}
                                                            data-cy={`counts-panel-item-${j}-description`}
                                                        >
                                                            {item.description} {item.size}
                                                        </Typography>
                                                    </div>
                                                    {!hideCounter && (
                                                        <Typography
                                                            data-cy={`counts-panel-item-${j}-counter`}
                                                            style={{ ...countsStyle, fontSize: '70%' }}
                                                        >
                                                            <span style={{ marginRight: theme.spacing.unit }}>
                                                                {getCustomerFirstNameAndLastInitial(count.operator)}
                                                            </span>
                                                            <span>
                                                                {moment(item.dateCounted).format('DD/MM/YY h:mma')}
                                                            </span>
                                                        </Typography>
                                                    )}
                                                </td>

                                                <td
                                                    style={{ width: '15%', textAlign: 'right' }}
                                                    data-cy={`counts-panel-item-${j}-quantity`}
                                                >
                                                    <Typography style={{ ...countsStyle }}>
                                                        {_.get(item, 'quantity', 0)}
                                                    </Typography>
                                                </td>
                                                <td
                                                    style={{ width: '15%', textAlign: 'right' }}
                                                    data-cy={`counts-panel-item-${j}-value`}
                                                >
                                                    <Typography style={countsStyle}>
                                                        {formatAsCurrency(item.value || 0)}
                                                    </Typography>
                                                </td>
                                            </tr>
                                        );
                                    });
                                })}
                                {(sessionlessFees || []).map(fee => {
                                    let index = _.get(fee, 'index', null);
                                    return (
                                        <tr key={`${fee.amount}-${index}`}>
                                            <td
                                                style={{
                                                    width: '38%'
                                                }}
                                            >
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    {!printingReceipt && (
                                                        <DeleteColumn
                                                            style={countsStyle}
                                                            show={!disableEditing}
                                                            disabled={disableEditing || inProgress}
                                                            onFunction={() => {
                                                                onRemoveCustomFee(index);
                                                                setIdleCountdown(0);
                                                                resetTimeoutCount();
                                                            }}
                                                        />
                                                    )}
                                                    {/* Needs Fixing */}
                                                    <Typography style={countsStyle}>{fee.description}</Typography>
                                                </div>
                                            </td>
                                            <td
                                                style={{
                                                    width: '32%'
                                                }}
                                            >
                                                {!hideCounter && (
                                                    <Typography style={{ ...countsStyle, fontSize: '70%' }}>
                                                        <span style={{ marginRight: theme.spacing.unit }}>
                                                            {getFeeName(fee)}
                                                        </span>
                                                        <span>
                                                            {!_.isNil(_.get(fee, 'dateApplied'))
                                                                ? moment(fee.dateApplied).format('DD/MM/YY h:mma')
                                                                : 'N/A'}
                                                        </span>
                                                    </Typography>
                                                )}
                                            </td>
                                            <td style={{ width: '15%', textAlign: 'right' }}>
                                                <Typography style={countsStyle}>{_.get(fee, 'quantity', 1)}</Typography>
                                            </td>
                                            <td>
                                                <Typography
                                                    style={{
                                                        color:
                                                            fee.amount > 0
                                                                ? theme.palette.error.main
                                                                : colors.green[400],
                                                        ...countsStyle,
                                                        textAlign: 'right'
                                                    }}
                                                >
                                                    {formatAsCurrency(-fee.amount)}
                                                </Typography>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <div
                            style={{
                                backgroundColor: commodityColors[bulk.skuType] || colors.grey[500],
                                position: 'absolute',
                                height: '100%',
                                width: 8,
                                right: 0,
                                top: 0
                            }}
                        />
                    </Card>
                )}
            </div>
        </div>
    );
});

const DeleteColumn = ({ show, disabled, onFunction }) => {
    if (!show) return <></>;
    return (
        // <td style={{ width: 24 }}>
        // {show && (
        <IconButton
            disabled={disabled}
            style={{ padding: 0 }}
            data-cy="delete-session-count"
            onClick={() => onFunction()}
        >
            <MDIcon path={mdiClose} size={0.8} color={colors.red[500]} />
        </IconButton>
        // )}
        // </td>
    );
};

export default withTheme()(CountsScreenV2);

function convertToImportName(iconName) {
    return _.camelCase(`mdi-${iconName}`);
}

function getFeeName(fee) {
    const appliedByName = _.get(fee, 'appliedByName');
    const appliedBy = _.get(fee, 'appliedBy');

    if (appliedByName == 'Applied by System') {
        return 'System';
    }

    if (!_.isNil(appliedByName)) {
        return appliedByName;
    }

    if (!_.isNil(appliedBy)) {
        return getCustomerFirstNameAndLastInitial(appliedBy);
    }

    if (fee.appliedAutomatically || fee.generatedOnBulkCompletion) {
        return 'System';
    }

    return '';
}
