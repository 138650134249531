import React, { useState, useContext, useEffect } from 'react';

import _ from 'lodash';
import moment from 'moment-timezone';
import { formatAsCurrency } from 'utils/misc';

import { getCustomerFirstNameAndLastInitial } from 'utils/misc';

import DialogTitlePrimary from 'components/MaterialUIExtensions/DialogTitlePrimary';
import OperatorContext from 'utils/contexts/OperatorContext';

import ReactJson from 'react-json-view';

import {
    colors,
    Grid,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    DialogContent,
    DialogActions,
    Dialog,
    Typography,
    withMobileDialog,
    CircularProgress,
    Divider,
    Collapse,
    IconButton,
    Icon,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    Tooltip,
    InputLabel,
    Select,
    MenuItem,
    OutlinedInput
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import { withTheme } from '@material-ui/core/styles';
import { Icon as MDIcon } from '@mdi/react';
import { mdiCheckboxMarkedCircleOutline, mdiCodeJson, mdiTagArrowDown } from '@mdi/js';

import bulkHelper from 'helpers/bulkHelper';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';
import { _bulk, _user } from 'std';

import CustomDebugDialog from 'components/CustomDebugDialog/CustomDebugDialog';

function BulkErrorDialog(props) {
    // Bulk errors (this component) currently only supports duplicate bulk errors but can be expanded...
    const { theme, http, bulk, open, operator, inProgress, refreshBulk, onClose } = props;

    const tableCellStyles1 = { width: '75%' };
    const tableCellStyles2 = { width: '25%' };
    const tableCellStyles = { padding: 0, color: theme.palette.text.secondary, borderBottom: 'none' };

    const [bagData, setBagData] = useState({});
    const [allErrors, setAllErrors] = useState([]);

    const [history, setHistory] = useState({});
    const [showHistory, setShowHistory] = useState(false);

    const [expandedItems, setExpandedItems] = useState([]);
    const [triggerPulse, setTriggerPulse] = useState(-1);

    const [allErrorsResolved, setAllErrorsResolved] = useState(false);

    const [selectedBagtag, setSelectedBagtag] = useState('all');
    const [selectedError, setSelectedError] = useState('all');

    const handleBagtagSelect = bagtag => {
        if (bagtag == selectedBagtag) {
            return;
        }

        setSelectedBagtag(bagtag);

        if (bagtag == 'all' && selectedError) {
            return;
        }

        if (bagtag == 'all' && !selectedError && allErrors.length > 0) {
            setSelectedError(allErrors[0]._id);

            return;
        }

        const currentError = _.find(allErrors, error => error._id == selectedError);
        if (currentError && currentError.bagtag == bagtag) {
            return;
        }

        const correspondingError = _.find(allErrors, error => error.bagtag == bagtag);
        if (correspondingError) {
            setSelectedError(correspondingError._id);
        } else {
            setSelectedError(null);
        }
    };

    const handleErrorSelect = errorId => {
        if (errorId == selectedError) {
            return;
        }

        setSelectedError(errorId);

        if (selectedBagtag == 'all') {
            return;
        }

        if (errorId == 'all') {
            const allErrorBagtags = _.map(allErrors, error => error.bagtag);
            if (
                allErrorBagtags.length > 0 &&
                _.every(allErrorBagtags, bagtag => bagtag == _.head(allErrorBagtags)) &&
                _.head(allErrorBagtags) == selectedBagtag
            ) {
                return;
            }

            setSelectedBagtag('all');

            return;
        }

        const correspondingError = _.find(allErrors, error => error._id == errorId);
        if (correspondingError) {
            setSelectedBagtag(correspondingError.bagtag);
        } else {
            setSelectedBagtag(null);
        }
    };

    const acceptCountsForError = (bagtag, errorIdx) => {
        const newBagData = _.cloneDeep(bagData);
        newBagData[bagtag].errors[errorIdx].resolution = 'accept';

        setBagData(newBagData);
    };

    const rejectCountsForError = (bagtag, errorIdx) => {
        const newBagData = _.cloneDeep(bagData);
        newBagData[bagtag].errors[errorIdx].resolution = 'reject';

        setBagData(newBagData);
    };

    const overrideCountsForError = (bagtag, errorIdx) => {
        const newBagData = _.cloneDeep(bagData);
        newBagData[bagtag].errors[errorIdx].resolution = 'override';

        setBagData(newBagData);
    };

    const handlePulse = bagtag => {
        setTriggerPulse(bagtag);
        setTimeout(() => setTriggerPulse(-1), 500);
    };

    const toggleExpandItem = expandItem => {
        if (expandedItems.includes(expandItem)) {
            setExpandedItems(_.filter(expandedItems, item => item != expandItem));
        } else {
            setExpandedItems([...expandedItems, expandItem]);
        }
    };

    const loadErrors = async () => {
        const res = await http.getJSON(`/bulks/${bulk._id}/detailedErrors`);

        if (res.ok) {
            const bulkErrors = _.get(res, 'data.bulk.bulkErrors', []);
            const unresolvedBulkErrors = _.filter(bulkErrors, error => !error.isResolved);
            for (let error of unresolvedBulkErrors) {
                error.resolution = null;
            }

            const groupedBulkErrors = {};
            for (let error of unresolvedBulkErrors) {
                const bagtag = error.bagtag;
                if (!bagtag) {
                    // TODO: ??
                    continue;
                }

                if (groupedBulkErrors[bagtag]) {
                    groupedBulkErrors[bagtag].errors.push(error);
                } else {
                    groupedBulkErrors[bagtag] = {
                        errors: [error],
                        mode: 'add'
                    };
                }
            }

            setBagData(groupedBulkErrors);
            setAllErrors(unresolvedBulkErrors);

            const firstError = _.first(unresolvedBulkErrors);
            if (firstError && firstError._id && firstError.bagtag) {
                setSelectedError(firstError._id);
                setSelectedBagtag(firstError.bagtag);
            }
        }
    };

    const handleResolveErrors = async () => {
        const submissionData = {};
        for (let bagtag of Object.keys(bagData)) {
            const data = bagData[bagtag];
            submissionData[bagtag] = {};
            submissionData[bagtag].mode = 'add';
            for (let error of _.get(data, 'errors', [])) {
                // sort of hacky...
                if (error.resolution == 'override') {
                    submissionData[bagtag].mode = 'override';
                    break;
                }
            }
            submissionData[bagtag].errors = data.errors.map(error => {
                return { history: _.get(error, 'history._id'), resolution: error.resolution };
            });
        }

        const res = await http.postJSON(`/bulks/${bulk._id}/resolveErrors`, { bagData: submissionData });

        if (res.ok) {
            setHistory({});
            setShowHistory(false);
            onClose();

            refreshBulk();
        }
    };

    const markResolvedErrors = async () => {
        const res = await http.getJSON(`/bulks/${bulk._id}/resolveErrors`);
        if (res.ok) {
            setHistory({});
            setShowHistory(false);
            onClose();

            refreshBulk();
        }
    };

    useEffect(() => {
        loadErrors();
    }, []);

    useEffect(() => {
        const bagtagsWithErrors = Object.keys(bagData);
        if (_.isEmpty(bagtagsWithErrors)) {
            return;
        }

        for (let bagtag of bagtagsWithErrors) {
            const errors = bagData[bagtag].errors;
            for (let error of errors) {
                if (!error.resolution) {
                    setAllErrorsResolved(false);
                    return;
                }
            }
        }

        setAllErrorsResolved(true);
    }, [open, bagData]);

    const handleClose = () => {
        const bagtagsWithErrors = Object.keys(bagData);
        if (_.isEmpty(bagtagsWithErrors)) {
            return;
        }

        for (let bagtag of bagtagsWithErrors) {
            const errors = bagData[bagtag].errors;
            for (let error of errors) {
                // Reset errors when closing dialog
                error.resolution = null;
            }
        }

        onClose();
    };

    const convertCounts = counts => {
        const rates = _.get(bulk, 'rates.rates', []);

        const convertedCounts = [];
        for (let count of counts) {
            const sku = count.sku;
            const rate = _.find(rates, rate => rate.sku == sku);
            const description = _.get(rate, 'descriptions.en', _.get(rate, 'description'));
            const superGroup = count.superGroup;

            const amount = count.quantity || 1;
            const refund = _.get(rate, 'value', 0);
            const countTotal = Number(amount) * Number(refund);

            convertedCounts.push({
                description,
                total: countTotal
                // isModified,
                // error,
                // sku,
                // quantity: amount,
                // superGroup
            });
        }
        return convertedCounts;
    };

    const aggregateCounts = counts => {
        const aggregatedCounts = {};
        for (let count of counts) {
            const description = count.description || 'Unknown';
            const refundAmount = count.total || 0;

            const mappedCount = aggregatedCounts[description];

            aggregatedCounts[description] = mappedCount ? mappedCount + refundAmount : refundAmount;
        }

        return Object.keys(aggregatedCounts).map(key => {
            return { description: key, total: aggregatedCounts[key] };
        });
    };

    const [orderTotal, setOrderTotal] = useState(0);

    const calculateOrderTotal = () => {
        const countingSessions = _.get(bulk, 'countingSessions', []);

        let newOrderTotal = 0;
        for (let countingSession of countingSessions) {
            const { bagtag, items } = countingSession;

            const currentCounts = convertCounts(items || []);

            const errors = _.get(bagData[bagtag], 'errors', []);

            let override = false;
            const newCounts = [];
            for (let error of errors) {
                if (error.resolution == 'accept') {
                    const items = _.get(error, 'history.data.reqBody.counts', []);
                    newCounts.push(...convertCounts(items));
                } else if (error.resolution == 'override') {
                    override = true;
                    const items = _.get(error, 'history.data.reqBody.counts', []);
                    newCounts.push(...convertCounts(items));
                }
            }

            if (!override) {
                for (let count of currentCounts) {
                    newOrderTotal += count.total;
                }
            }

            for (let count of newCounts) {
                newOrderTotal += count.total;
            }
        }

        setOrderTotal(newOrderTotal);
    };

    const [lockedBulk, setLockedBulk] = useState(true);

    useEffect(() => {
        if (bulk) {
            setLockedBulk(_bulk.isCompleted(bulk));
        }

        calculateOrderTotal();
    }, [open, bulk, bagData]);

    return (
        <>
            <Dialog data-cy="multi-step-dialog" fullWidth open={open}>
                <DialogTitlePrimary>Errors</DialogTitlePrimary>
                <DialogContent>
                    {_bulk.isCompleted(bulk) && (
                        <Grid item xs={12}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginTop: theme.spacing.unit * 2
                                }}
                            >
                                <Icon
                                    style={{
                                        marginRight: theme.spacing.unit,
                                        color: colors.red[500],
                                        fontSize: '24px'
                                    }}
                                >
                                    warning
                                </Icon>
                                <Typography>
                                    Order has been {_bulk.getStatus(bulk)} and is not editable. Create a new adjustment
                                    order if you need to adjust the user balance.
                                </Typography>
                            </div>
                        </Grid>
                    )}
                    <Grid container style={{ marginTop: theme.spacing.unit * 2 }}>
                        <Grid item xs={12} sm={6} style={{ display: 'flex', flexDirection: 'column' }}>
                            <div
                                style={{
                                    height: '28px',
                                    marginBottom: theme.spacing.unit * 2
                                }}
                            >
                                <Typography
                                    style={{
                                        fontWeight: 'bold'
                                    }}
                                >
                                    Order ({formatAsCurrency(Math.abs(orderTotal))})
                                </Typography>
                            </div>
                            <FormControl fullWidth style={{ marginBottom: theme.spacing.unit }}>
                                <InputLabel variant="outlined" htmlFor="bagtag-filter">
                                    Bagtag
                                </InputLabel>
                                <Select
                                    size="small"
                                    value={selectedBagtag}
                                    onChange={e => handleBagtagSelect(e.target.value)}
                                    inputProps={{
                                        name: 'bagtag-filter',
                                        id: 'bagtag-filter'
                                    }}
                                    input={
                                        <OutlinedInput
                                            // size="small"
                                            labelWidth={48}
                                            name="bagtag"
                                            id="outlined-age-simple"
                                            // style={{ padding: theme.spacing.unit * 1.5 }}
                                        />
                                    }
                                    // style={{
                                    //     minWidth: 168
                                    // }}
                                >
                                    <MenuItem value={'all'} key={'all-bagtags'}>
                                        {'All Bagtags'}
                                    </MenuItem>
                                    {_.get(bulk, 'countingSessions', []).map(session => {
                                        const { bagtag, items } = session;

                                        return (
                                            <MenuItem value={bagtag} key={bagtag}>
                                                {bagtag}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                            <Paper style={{ width: '100%', marginRight: theme.spacing.unit / 2 }}>
                                <Table
                                    style={{
                                        borderCollapse: 'separate',
                                        borderSpacing: 5
                                    }}
                                >
                                    <TableBody
                                    // style={{
                                    //     flex: 1,
                                    //     display: 'flex',
                                    //     flexDirection: 'column'
                                    // }}
                                    >
                                        {_.filter(
                                            _.get(bulk, 'countingSessions', []),
                                            session => selectedBagtag == 'all' || selectedBagtag == session.bagtag
                                        ).map((session, idx) => {
                                            const { bagtag, items } = session;

                                            // if (selectedBagtag != 'all' && selectedBagtag != bagtag) return <></>; // quick patch

                                            const errors = _.get(bagData[bagtag], 'errors', []);

                                            const currentCounts = convertCounts(items || []);

                                            let override = false;

                                            const newCounts = [];
                                            for (let error of errors) {
                                                if (error.resolution == 'accept') {
                                                    const items = _.get(error, 'history.data.reqBody.counts', []);
                                                    newCounts.push(...convertCounts(items));
                                                } else if (error.resolution == 'override') {
                                                    override = true;
                                                    const items = _.get(error, 'history.data.reqBody.counts', []);
                                                    newCounts.push(...convertCounts(items));
                                                }
                                            }

                                            let totalRefund = 0;
                                            for (let count of newCounts) {
                                                totalRefund += count.total;
                                            }
                                            if (!override) {
                                                for (let count of currentCounts) {
                                                    totalRefund += count.total;
                                                }
                                            }

                                            return (
                                                <>
                                                    {idx != 0 && (
                                                        <TableRow style={{ height: 0 }}>
                                                            <TableCell colSpan={2} style={{ padding: 0 }}>
                                                                <Divider />
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                    {/* {selectedBagtag == 'all' && ( */}
                                                    <TableRow style={{ height: 20 }}>
                                                        <TableCell
                                                            style={{
                                                                ...tableCellStyles,
                                                                ...tableCellStyles1,
                                                                paddingTop: 0,
                                                                paddingBottom: 0,
                                                                textAlign: 'left',
                                                                fontWeight: 'bold'
                                                            }}
                                                        >
                                                            {bagtag}
                                                        </TableCell>

                                                        <TableCell
                                                            style={{
                                                                ...tableCellStyles,
                                                                ...tableCellStyles2,
                                                                paddingTop: 0,
                                                                paddingBottom: 0,
                                                                fontWeight: 'bold',
                                                                textAlign: 'right'
                                                            }}
                                                        >
                                                            {formatAsCurrency(Math.abs(totalRefund))}
                                                        </TableCell>
                                                    </TableRow>
                                                    {/* )} */}
                                                    {currentCounts.map(item => {
                                                        return (
                                                            <TableRow style={{ height: 20 }}>
                                                                <TableCell
                                                                    style={{
                                                                        ...tableCellStyles,
                                                                        ...tableCellStyles1,
                                                                        paddingTop: 0,
                                                                        paddingBottom: 0,
                                                                        textAlign: 'left',
                                                                        color: override
                                                                            ? colors.red[300]
                                                                            : 'rgba(0, 0, 0, 0.54)'
                                                                    }}
                                                                >
                                                                    {!override && [
                                                                        <>
                                                                            <span style={{ fontWeight: 500 }}>
                                                                                [ORIGINAL]{' '}
                                                                            </span>
                                                                            {item.description}
                                                                        </>
                                                                    ]}
                                                                    {override && (
                                                                        <>
                                                                            <span style={{ fontWeight: 500 }}>
                                                                                [REMOVED]{' '}
                                                                            </span>
                                                                            {item.description}
                                                                        </>
                                                                    )}
                                                                </TableCell>
                                                                <TableCell
                                                                    style={{
                                                                        ...tableCellStyles,
                                                                        ...tableCellStyles2,
                                                                        paddingTop: 0,
                                                                        paddingBottom: 0,
                                                                        textAlign: 'right',
                                                                        color: override
                                                                            ? colors.red[300]
                                                                            : 'rgba(0, 0, 0, 0.54)'
                                                                    }}
                                                                >
                                                                    {formatAsCurrency(Math.abs(item.total))}
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                    {newCounts.map(item => {
                                                        return (
                                                            <TableRow style={{ height: 20 }}>
                                                                <TableCell
                                                                    style={{
                                                                        ...tableCellStyles,
                                                                        ...tableCellStyles1,
                                                                        paddingTop: 0,
                                                                        paddingBottom: 0,
                                                                        textAlign: 'left',
                                                                        color: colors.green[500]
                                                                    }}
                                                                >
                                                                    <span style={{ fontWeight: 500 }}>[ADDED] </span>
                                                                    {item.description}
                                                                </TableCell>
                                                                <TableCell
                                                                    style={{
                                                                        ...tableCellStyles,
                                                                        ...tableCellStyles2,
                                                                        paddingTop: 0,
                                                                        paddingBottom: 0,
                                                                        textAlign: 'right',
                                                                        color: colors.green[500]
                                                                    }}
                                                                >
                                                                    {formatAsCurrency(Math.abs(item.total))}
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                </>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6} style={{ display: 'flex', flexDirection: 'column' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    marginBottom: theme.spacing.unit * 2,
                                    height: '28px'
                                }}
                            >
                                <Typography
                                    style={{
                                        marginLeft: theme.spacing.unit,
                                        fontWeight: 'bold'
                                    }}
                                >
                                    Unresolved Errors (
                                    {
                                        _.filter(
                                            _.flatten(Object.values(bagData).map(data => data.errors)),
                                            error => !_.isNil(error.resolution)
                                        ).length
                                    }
                                    /{allErrors.length})
                                </Typography>
                                {selectedError != 'all' && (
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <IconButton
                                            onClick={() => {
                                                const currentErrorIdx = _.findIndex(
                                                    allErrors,
                                                    error => error._id == selectedError
                                                );
                                                if (currentErrorIdx <= 0) {
                                                    return;
                                                }

                                                const nextError = allErrors[currentErrorIdx - 1];
                                                handleErrorSelect(nextError._id);
                                            }}
                                            disabled={_.findIndex(allErrors, error => error._id == selectedError) <= 0}
                                            style={{ padding: theme.spacing.unit / 2, marginRight: theme.spacing.unit }}
                                        >
                                            <Icon
                                                style={{
                                                    color: colors.grey[500],
                                                    fontSize: '18px'
                                                }}
                                            >
                                                chevron_left
                                            </Icon>
                                        </IconButton>
                                        <Typography>
                                            {allErrors.length <= 0
                                                ? 0
                                                : 1 + _.findIndex(allErrors, error => error._id == selectedError)}
                                            /{allErrors.length}
                                            {/* {allErrors.length <= 0
                                            ? 0
                                            : 1 +
                                              (selectedBagtag == 'all'
                                                  ? _.findIndex(allErrors, error => error._id == selectedError)
                                                  : _.findIndex(
                                                        _.filter(allErrors, error => error.bagtag == selectedBagtag),
                                                        error => error._id == selectedError
                                                    ))}
                                        /
                                        {selectedBagtag == 'all'
                                            ? allErrors.length
                                            : _.filter(allErrors, error => error.bagtag == selectedBagtag).length} */}
                                        </Typography>

                                        <IconButton
                                            onClick={() => {
                                                const currentErrorIdx = _.findIndex(
                                                    allErrors,
                                                    error => error._id == selectedError
                                                );

                                                if (currentErrorIdx == allErrors.length - 1) {
                                                    return;
                                                }

                                                const nextError = allErrors[currentErrorIdx + 1];
                                                handleErrorSelect(nextError._id);
                                            }}
                                            disabled={
                                                _.findIndex(allErrors, error => error._id == selectedError) ==
                                                allErrors.length - 1
                                            }
                                            style={{ padding: theme.spacing.unit / 2, marginLeft: theme.spacing.unit }}
                                        >
                                            <Icon
                                                style={{
                                                    color: colors.grey[500],
                                                    fontSize: '20px'
                                                }}
                                            >
                                                chevron_right
                                            </Icon>
                                        </IconButton>
                                    </div>
                                )}
                            </div>

                            <FormControl
                                fullWidth
                                style={{ marginBottom: theme.spacing.unit, marginLeft: theme.spacing.unit / 2 }}
                            >
                                <InputLabel variant="outlined" htmlFor="errors-filter">
                                    Errors
                                </InputLabel>
                                <Select
                                    size="small"
                                    value={selectedError}
                                    onChange={e => handleErrorSelect(e.target.value)}
                                    placeholder="No Errors"
                                    inputProps={{
                                        name: 'errors-filter',
                                        id: 'errors-filter'
                                    }}
                                    input={
                                        <OutlinedInput
                                            // size="small"
                                            labelWidth={48}
                                            name="bagtag"
                                            id="outlined-age-simple"
                                            // style={{ padding: theme.spacing.unit * 1.5 }}
                                        />
                                    }
                                    // style={{
                                    //     minWidth: 168
                                    // }}
                                >
                                    <MenuItem
                                        value={'all'}
                                        key={'all-errors'}
                                        style={{ display: 'flex', alignItems: 'center' }}
                                    >
                                        All Errors
                                    </MenuItem>
                                    {_.filter(_.flatten(Object.values(bagData).map(data => data.errors)), error => {
                                        if (selectedBagtag == 'all') return true;
                                        if (error.bagtag == selectedBagtag) return true;

                                        return false;
                                    }).map(error => {
                                        // const { bagtag, items } = session;
                                        const { resolution } = error;

                                        let color = 'default';
                                        if (['accept', 'override'].includes(resolution)) {
                                            color = colors.green[500];
                                        } else if (resolution == 'reject') {
                                            color = colors.red[500];
                                        }

                                        return (
                                            <MenuItem
                                                value={error._id}
                                                key={error._id}
                                                style={{ display: 'flex', alignItems: 'center', color }}
                                            >
                                                {/* <Icon style={{fontSize:'8px', color}} >
                                                    fiber_manual_record
                                                </Icon> */}
                                                <span>Duplicate {resolution && `[${resolution.toUpperCase()}]`}</span>
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                            {// !allErrorsResolved &&
                            allErrors.map(rawError => {
                                const bagtag = rawError.bagtag;
                                const error = _.find(bagData[bagtag].errors, err => err._id == rawError._id);
                                const idx = _.findIndex(bagData[bagtag].errors, err => err._id == rawError._id);

                                // find the next unresolved error for the bagtag
                                if (!error) {
                                    return <></>;
                                }

                                if (selectedError != 'all' && error._id != selectedError) {
                                    return <></>;
                                }

                                const resolution = error.resolution;

                                const machineType = _.get(
                                    error,
                                    'history.data.reqBody.metadata.rvm.type',
                                    'Unknown machine type'
                                );
                                const serialNumber = _.get(
                                    error,
                                    'history.data.reqBody.metadata.rvm.serialNumber',
                                    'Unknown serial number'
                                );
                                const collectorCode = _.get(
                                    error,
                                    'history.data.reqBody.metadata.location.customerId',
                                    'unknown collector'
                                );
                                const dateSubmitted = moment(error.date).format('MMM D, YYYY h:mm A');

                                const items = _.get(error, 'history.data.reqBody.counts', []);
                                const errorCounts = convertCounts(items);

                                let errorRefund = 0;
                                for (let count of errorCounts) {
                                    errorRefund += count.total;
                                }

                                let color = 'rgba(0, 0, 0, 0.54)';
                                if (['accept', 'override'].includes(resolution)) {
                                    color = colors.green[500];
                                } else if (resolution == 'reject') {
                                    color = colors.red[500];
                                }

                                return (
                                    // <div
                                    //     style={{
                                    //         display: 'flex',
                                    //         flexDirection: 'row',
                                    //         marginBottom: theme.spacing.unit * 2
                                    //     }}
                                    // >
                                    <Paper
                                        className={triggerPulse == bagtag ? 'pulse-effect' : ''}
                                        style={{
                                            width: '100%',
                                            marginLeft: theme.spacing.unit / 2,
                                            marginBottom: theme.spacing.unit / 2
                                        }}
                                    >
                                        <Table
                                            style={{
                                                borderCollapse: 'separate',
                                                borderSpacing: 5
                                            }}
                                        >
                                            <TableBody
                                            // style={{
                                            //     flex: 1,
                                            //     display: 'flex',
                                            //     flexDirection: 'column'
                                            // }}
                                            >
                                                {/* {selectedBagtag == 'all' && ( */}
                                                <TableRow style={{ height: 20 }}>
                                                    <TableCell
                                                        style={{
                                                            ...tableCellStyles,
                                                            ...tableCellStyles1,
                                                            paddingTop: 0,
                                                            paddingBottom: 0,
                                                            fontWeight: 'bold',
                                                            textAlign: 'left',
                                                            color,
                                                            display: 'flex',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <Tooltip
                                                            title={`${machineType}-${serialNumber} at ${collectorCode} submitted duplicate container counts for ${bagtag} at ${dateSubmitted}.`}
                                                        >
                                                            <Icon
                                                                style={{
                                                                    color,
                                                                    fontSize: '14px'
                                                                }}
                                                            >
                                                                info
                                                            </Icon>
                                                        </Tooltip>
                                                        <span>{bagtag}</span>
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            ...tableCellStyles,
                                                            ...tableCellStyles2,
                                                            paddingTop: 0,
                                                            paddingBottom: 0,
                                                            fontWeight: 'bold',
                                                            textAlign: 'right',
                                                            color
                                                        }}
                                                    >
                                                        {formatAsCurrency(Math.abs(errorRefund))}
                                                    </TableCell>
                                                </TableRow>
                                                {/* )} */}
                                                {aggregateCounts(errorCounts || []).map(currentCount => {
                                                    const description = currentCount.description;
                                                    const newCountRefund = currentCount.total;
                                                    // const modified = currentCount.isModified;

                                                    // May want to aggregate the counts here...

                                                    return (
                                                        <TableRow
                                                            style={{
                                                                height: 20
                                                            }}
                                                        >
                                                            <TableCell
                                                                style={{
                                                                    ...tableCellStyles,
                                                                    ...tableCellStyles1,
                                                                    paddingTop: 0,
                                                                    paddingBottom: 0,
                                                                    textAlign: 'left',
                                                                    color
                                                                }}
                                                            >
                                                                {description}
                                                            </TableCell>
                                                            <TableCell
                                                                style={{
                                                                    ...tableCellStyles,
                                                                    ...tableCellStyles2,
                                                                    paddingTop: 0,
                                                                    paddingBottom: 0,
                                                                    textAlign: 'right',
                                                                    color
                                                                }}
                                                            >
                                                                {formatAsCurrency(Math.abs(newCountRefund))}
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                                {!lockedBulk && (
                                                    <TableRow style={{ height: 0 }}>
                                                        <TableCell colSpan={2} style={{ padding: 0 }}>
                                                            <Divider />
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                                {!lockedBulk && (
                                                    <TableRow style={{ height: 20 }}>
                                                        <TableCell
                                                            colSpan={2}
                                                            align="center"
                                                            style={{
                                                                ...tableCellStyles,
                                                                // ...tableCellStyles,
                                                                // ...tableCellStyles1,
                                                                paddingTop: 0,
                                                                paddingBottom: 0
                                                                // display: 'flex',
                                                                // justifyContent: 'space-around',
                                                                // width: '100%'
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    width: '100%'
                                                                }}
                                                            >
                                                                <Button
                                                                    size="small"
                                                                    variant="contained"
                                                                    color={
                                                                        resolution == 'accept' ? 'secondary' : 'primary'
                                                                    }
                                                                    style={{
                                                                        height: '25px',
                                                                        // display: 'flex',
                                                                        // alignItems: 'center',
                                                                        marginRight: theme.spacing.unit
                                                                    }}
                                                                    onClick={() => {
                                                                        handlePulse(bagtag);
                                                                        setTimeout(() => {
                                                                            acceptCountsForError(bagtag, idx);
                                                                        }, 500);
                                                                        // setMode(bagtag, 'add');
                                                                    }}
                                                                >
                                                                    <span>Add</span>
                                                                    {/* {mode == 'add' && (
                                                                                    <CheckIcon
                                                                                        style={{ fontSize: '14px' }}
                                                                                    />
                                                                                )} */}
                                                                </Button>
                                                                <Button
                                                                    size="small"
                                                                    variant="contained"
                                                                    color={
                                                                        resolution == 'reject' ? 'secondary' : 'primary'
                                                                    }
                                                                    style={{
                                                                        height: '25px',
                                                                        // display: 'flex',
                                                                        // alignItems: 'center',
                                                                        marginRight: theme.spacing.unit
                                                                    }}
                                                                    onClick={() => {
                                                                        handlePulse(bagtag);
                                                                        setTimeout(() => {
                                                                            rejectCountsForError(bagtag, idx);
                                                                        }, 500);

                                                                        // setMode(bagtag, 'reject');
                                                                    }}
                                                                >
                                                                    Reject
                                                                </Button>
                                                                <Button
                                                                    size="small"
                                                                    variant="contained"
                                                                    color={
                                                                        resolution == 'override'
                                                                            ? 'secondary'
                                                                            : 'primary'
                                                                    }
                                                                    style={{
                                                                        height: '25px'
                                                                        // display: 'flex',
                                                                        // alignItems: 'center'
                                                                    }}
                                                                    onClick={() => {
                                                                        handlePulse(bagtag);
                                                                        setTimeout(() => {
                                                                            overrideCountsForError(bagtag, idx);
                                                                        }, 500);
                                                                        // setMode(bagtag, 'override');
                                                                    }}
                                                                >
                                                                    <span>Override</span>
                                                                    {/* {mode == 'override' && (
                                                                                    <CheckIcon
                                                                                        style={{ fontSize: '14px' }}
                                                                                    />
                                                                                )} */}
                                                                </Button>
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </Paper>
                                    // </div>
                                );
                            })}
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button color="default" onClick={handleClose}>
                        Cancel
                    </Button>
                    {_bulk.hasUnresolvedErrors(bulk) && !lockedBulk && (
                        <Button color="primary" onClick={() => handleResolveErrors()} disabled={!allErrorsResolved}>
                            Accept Changes
                        </Button>
                    )}
                    {_bulk.hasUnresolvedErrors(bulk) && lockedBulk && (
                        <Button color="primary" onClick={() => markResolvedErrors()}>
                            Mark As Resolved
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
            <CustomDebugDialog open={showHistory} json={history} onClose={() => setShowHistory(false)} />
        </>
    );
}

export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(BulkErrorDialog));
